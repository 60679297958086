import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { errorCodes as errorCodesAction } from '~lib/platform/code-mapping/store/actions';
import { config as configAction, createEstimate } from '~lib/platform/ltm/store/actions';
import { LtmConfig, UseClass } from '~lib/platform/ltm/types';
import { AsyncState } from '~lib/shared/redux/types/state';
import { gatherClientContext } from '~website/helpers/client-context';

/**
 * This hooks returns an AsyncState<boolean, Err>, where the error appears only if the vehicle isn't quotable
 * Therefore, could either return `true` or `false` where true means we could get an estimate, false means we couldn't
 * but it's unrelated to the vehicle itself
 */
const useVehicleQuotable = (vehicleId: string) => {
	const dispatch = useDispatch();

	const userId = useSelector(s => s.internal.auth.user?.response);
	const errorCodes = useSelector(s => s.platform.codeMapping.errorCodes);
	const errorCodesStatus = useAsyncStateStatus(errorCodes).status;
	const config = useSelector(s => s.platform.ltm.config);
	const configStatus = useAsyncStateStatus(config).status;
	const estimate = useSelector(s => s.platform.ltm.createEstimate[vehicleId]);
	const startDate = getStartDate(config);
	const useClass = getUseClass(config);
	const ready = Boolean(userId && vehicleId && errorCodes.response && config.response);

	const state: AsyncState<boolean> = useMemo(() => {
		if (estimate?.response)
			return { fetching: false, response: true };

		if (estimate?.error && ready) {
			const vehicleCodes = errorCodes.response
				.filter(c => c.category === 'vehicle')
				.map(c => c.code);
			const cannotQuote = estimate.error.code === 'cannot_quote';
			const reasons = (estimate.error.reasons ?? [])
				.filter(r => vehicleCodes.includes(r.code));

			if (cannotQuote && reasons.length)
				return { fetching: false, error: new Cher('cannot_quote', reasons) };

			return { fetching: false, response: false };
		}

		return { fetching: true };
	}, [estimate, ready, errorCodes]);

	useEffect(() => {
		if (!ready)
			return;

		dispatch(createEstimate.request({
			requestId: vehicleId,
			vehicleId,
			userId,
			startDate,
			useClass,
			voluntaryExcess: null,
			smartPricing: null,
			location: null,
			ncb: null,
			reason: 'app_preload',
			type: 'motor_long_term',
			clientContext: gatherClientContext(),
		}));
	}, [dispatch, ready, vehicleId, userId, startDate, useClass]);

	useEffect(() => {
		if (errorCodesStatus === 'pending')
			dispatch(errorCodesAction.request());
	}, [dispatch, errorCodesStatus]);

	useEffect(() => {
		if (configStatus === 'pending' && userId) {
			dispatch(configAction.request({
				client: {
					platform: 'web',
					build: '1',
					version: '1.1.1',
				},
				userId,
			}));
		}
	}, [dispatch, configStatus, userId]);

	return state;
};

function getStartDate(config: AsyncState<LtmConfig>): string | undefined {
	if (!config.response)
		return void 0;

	const now = new Date();

	now.setDate(now.getDate() + config.response.startDate.default);
	now.setHours(0, 0, 0, 0);

	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0');
	const day = String(now.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}

function getUseClass(config: AsyncState<LtmConfig>): UseClass | undefined {
	if (!config.response)
		return void 0;

	const classes = config.response.useClass.classes;

	return classes.includes('sdpc') ? 'sdpc' : classes[0];
}

export default useVehicleQuotable;
