import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';
import { quoteActions } from '~website/features/quote/store/actions';

interface ProductString {
	title: string;
	feature_one?: string;
	feature_two?: string;
	smart_pricing_feature_two?: string;
	[key: string]: string;
}

const productStringFallback = {
	stm: {
		title: 'From 1 hour to 28 days',
		feature_one: 'Quick last minute insurance',
	},
	ltm: {
		title: 'From 1 month to 1 year',
		feature_two: 'Lower prices for good drivers',
	},
};

export default function Duration() {
	const dispatch = useDispatch();
	const selectedFlow = useSelector(s => s.internal.quote.state.flow);

	const hoursIllustration = useDesignSystemAsset({ type: 'illustration', value: 'il_hours_white.svg' });
	const yearIllustration = useDesignSystemAsset({ type: 'illustration', value: 'il_year_purple.svg' });

	const contentKey = useContentKey('strings');
	const stm = contentKey('components.promo_stm_mini.v2', {}) as ProductString;
	const ltm = contentKey('components.promo_ltm_mini.v2', {}) as ProductString;

	const { stm: stmFb, ltm: ltmFb } = productStringFallback;
	const products = [
		{
			title: stm?.title ?? stmFb.title,
			subtitle: stm?.feature_one ?? stmFb.feature_one,
			illustration: hoursIllustration,
			flow: 'hourly',
		},
		{
			title: ltm?.title ?? ltmFb.title,
			subtitle: (ltm?.feature_two || ltm?.smart_pricing_feature_two) ?? ltmFb.feature_two,
			illustration: yearIllustration,
			flow: 'monthly',
		},
	];

	return (
		<Container>
			{products.map(product => (
				<DurationCard
					$active={product.flow === selectedFlow}
					data-product={product}
					key={product.flow}
					onClick={() => {
						dispatch(quoteActions.setFlow(product.flow));

						dispatch(
							trackQuoteEvent({
								action: 'product_selected',
								value: product.flow === 'monthly' ? 'LTM' : 'STM',
							}),
						);
					}}
				>
					<IconContainer>
						<img src={product.illustration} />
					</IconContainer>
					<div>
						<DurationCardTitle $bold $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{product.title}
						</DurationCardTitle>
						{product.subtitle && (
							<Typography $type={'Body.Small'} $marginTop={'large'}>
								{product.subtitle}
							</Typography>
						)}
					</div>
				</DurationCard>
			))}
		</Container>
	);
}

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;

	img {
		height: 40px;
		width: 40px;
	}
`;

const Container = styled.div`
	display: grid;
	grid-column-gap: ${p => p.theme.spacing.regular};
	grid-template-columns: 1fr 1fr;
	margin-top: ${p => p.theme.spacing.large};
	text-align: center;

	@media (min-width: 1024px) {
		margin-top: ${p => p.theme.spacing.small};
	}
`;

const DurationCard = styled.button<{ $active: boolean }>`
	background: ${p => (p.$active ? `${p.theme.ui.primaryFillMuted}` : `${p.theme.ui.secondarySurface}`)};
	border: none;
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.extraLarge} ${p => p.theme.spacing.large};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;

	box-shadow: 0 0 0
		${p =>
			p.$active ? `1px ${p.theme.ui.borderInteractiveSelected}` : `1px ${p.theme.ui.borderInteractiveDefault}`};
	outline: none;

	:active,
	:focus {
		outline: none;
	}
`;

const DurationCardTitle = styled(Typography)`
	margin: 0 auto;
	width: 80%;

	@media (min-width: 1024px) {
		width: 60%;
	}
`;
