import React, { forwardRef } from 'react';
import ButtonWrapper, { ButtonTextWrapper } from './atoms/ButtonWrapper';
import IconWrapper from './atoms/IconWrapper';
import Overlay from './atoms/Overlay';
import SpinnerWrapper from './atoms/SpinnerWrapper';
import Icon from '~lib/frontend/design-system/components/Icon';
const Button = forwardRef((props, ref) => {
    const { $leadingIcon, $trailingIcon, disabled, 'aria-label': ariaLabel, type = 'button', children, ...extraProps } = props;
    const { $loading, $type = 'primary', $size = 'medium', $variant = 'default', $stretch } = props;
    const defaultAriaLabel = children ? children.toString() : 'Button';
    return (React.createElement(ButtonWrapper, { ...extraProps, ref: ref, "$leadingIcon": Boolean($leadingIcon), "$trailingIcon": Boolean($trailingIcon), disabled: disabled || $loading, "aria-label": ariaLabel || defaultAriaLabel, type: type },
        $loading && (React.createElement(SpinnerWrapper, { buttonType: $type, size: $size },
            React.createElement(Icon, { icon: 'ic_loading_spinner', "$size": '18px', "$color": 'neutralFill' }))),
        $leadingIcon && (React.createElement(IconWrapper, { "$type": $type, "$leadingIcon": Boolean($leadingIcon), "$stretch": $stretch, "$size": $size },
            React.createElement(Icon, { icon: $leadingIcon, "$size": '18px', "$color": 'neutralFill' }))),
        React.createElement(ButtonTextWrapper, null, children),
        $trailingIcon && (React.createElement(IconWrapper, { "$type": $type, "$trailingIcon": Boolean($trailingIcon), "$stretch": $stretch, "$size": $size },
            React.createElement(Icon, { icon: $trailingIcon, "$size": '18px', "$color": 'neutralFill' }))),
        React.createElement(Overlay, { size: $size, buttonType: $type, variant: $variant })));
});
export default Button;
