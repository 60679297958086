export default function configureSentry(_app, dsn, userId) {
    // eslint-disable-next-line no-process-env
    if (process.env.REACT_CUVVA_DEVELOPMENT)
        return; // No sentry when running locally, no no no
    // Lazy load Sentry SDK with all necessary integrations
    import('@sentry/browser').then(Sentry => {
        const options = {
            dsn,
            environment: window.cuvva.client.env,
            release: window.cuvva.deployment.gitCommit,
            tracesSampleRate: 0.2,
            tracePropagationTargets: ['api.prod.cuv-prod.com', /api\.[a-z0-9]+\.cuv-nonprod\.com/],
            integrations: [Sentry.browserTracingIntegration()],
            replaysOnErrorSampleRate: 0.1,
        };
        Sentry.init(options);
        if (userId)
            Sentry.setUser({ id: userId });
        // Lazy load Replay integration
        import('@sentry/browser').then(lazyLoadedSentry => {
            Sentry.addIntegration(lazyLoadedSentry.replayIntegration());
        });
    });
}
