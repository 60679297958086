import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import ErrorMessage from '~lib/frontend/design-system/components/input/atoms/ErrorMessage';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import RegPlateInput from '~lib/frontend/design-system/components/input/molecules/RegPlateInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { findVehicleId, getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import { ContentContainer } from '~website/components/SplitUI';
import { trackQuoteEvent, trackQuoteScreenView } from '~website/features/analytics/store/actions';
import { quoteActions } from '~website/features/quote/store/actions';
import { PreloadedVehicle } from '~website/features/quote/store/types';
import useCheckedTyping from '~website/hooks/use-checked-typing';

interface RecentSearchesInterface {
	vehicles: PreloadedVehicle[];
	trackRecentSearch: () => void;
}

const RecentSearches: React.FCWithChildren<RecentSearchesInterface> = ({ vehicles, trackRecentSearch }) => {
	const dispatch = useDispatch();

	const setVehicle = (vehicleId: string) => {
		trackRecentSearch();
		dispatch(quoteActions.setFlow('hourly'));
		dispatch(quoteActions.setVehicle({ vehicleId }));
		dispatch(push(createAwareUrl('/quote/trip-start')));
	};

	return (
		<RecentSearchesContainer>
			<Typography $type={'Label.Medium'} $color={'textOnSurfaceBackgroundMuted'} $marginBottom={'extraSmall'}>
				{'Recent searches'}
			</Typography>
			<PillsContainer>
				{vehicles?.map(v => (
					<Pill
						key={v.vrm}
						onClick={() => setVehicle(v.vehicleId)}
						data-vrm={v.vrm}
					>
						<VehicleManufacturer make={v.make} size={'18px'} color={'primaryFill'} />
						<Typography $type={'Label.Small'} $color={'primaryAction'}>
							<div>{v.vrm}</div>
						</Typography>
					</Pill>
				))}
			</PillsContainer>
		</RecentSearchesContainer>
	);
};

interface SearchVehicleProps {
	vehicleId?: string;
}

const customErrorMessage = 'Not a valid reg plate, double check and try again';

const SearchVehicle: React.FCWithChildren<SearchVehicleProps> = () => {
	const dispatch = useDispatch();
	const [input, setInput] = useState<string>('');
	const [vehicleId, setVehicleId] = useState<string|null>(null);

	const { checkStartedTyping, setCheckStartedTyping } = useCheckedTyping({
		action: 'vehicle_reg_manual_input_started',
	});

	const vehicleSearch = useSelector(s => s.platform.vehicle.findVehicleId[`vrm:${input}`]);
	const recentVehicles: PreloadedVehicle[] = useSelector(s => s.internal.quote.state.recentVehicles);
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleStatus = useAsyncStateStatus(vehicle).status;

	const onSearch = () => {
		dispatch(findVehicleId.request({ field: 'vrm', value: input }));
		dispatch(trackQuoteEvent({ action: 'vehicle_reg_search_selected' }));
	};

	const onInputChange = (input: string) => {
		if (!checkStartedTyping)
			setCheckStartedTyping(true);

		setInput(input);
	};

	useEffect(() => {
		setVehicleId(vehicleSearch?.response?.id ?? null);
	}, [vehicleSearch]);

	useEffect(() => {
		if (vehicleId && vehicle?.response) {
			dispatch(quoteActions.setVehicle({ vehicleId }));
			dispatch(quoteActions.setFlow('hourly'));
			dispatch(push(createAwareUrl('/quote/trip-start')));
		}
	}, [dispatch, vehicleId, vehicle]);

	useEffect(() => {
		if (vehicleId && vehicleStatus === 'pending')
			dispatch(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
	}, [vehicleId, vehicleStatus, dispatch]);

	useEffect(() => {
		dispatch(trackQuoteScreenView({ screenName: 'none_screenView_regInput' }));
	}, [dispatch]);

	return (
		<SearchVehicleContainer
			as={'form'}
			onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
				e.preventDefault();

				onSearch();
			}}
		>
			<Typography
				$type={'Heading.Small'}
				$color={'textOnSurfaceBackground'}
				$align={'center'}
				$marginBottom={'24px'}
			>
				{'What car are you insuring?'}
			</Typography>

			<InputWrap>
				<InputDecorator error={(vehicle?.error || vehicleSearch?.error) && customErrorMessage}>
					<RegPlateInput
						value={input}
						onChange={input => onInputChange(input)}
						placeholder={'Search number plate'}
						ariaLabel={'Number plate'}
						onFocus={() => dispatch(trackQuoteEvent({ action: 'vehicle_reg_manual_input_selected' }))}
					/>
				</InputDecorator>
			</InputWrap>

			{!vehicle?.response && (
				<Margin $marginTop={'large'}>
					<Button
						$stretch
						$size={'large'}
						$loading={vehicle?.fetching || vehicleSearch?.fetching}
						onClick={onSearch}
					>
						{'Find car'}
					</Button>
				</Margin>
			)}

			{recentVehicles?.length > 0 && (
				<RecentSearches
					vehicles={recentVehicles}
					trackRecentSearch={() => dispatch(trackQuoteEvent({ action: 'vehicle_reg_recent_search_selected' }))}
				/>
			)}

			<TermsAndConditions>
				<Typography $type={'Body.Small'} $color={'textMinor'} $display={'inline'}>
					{'By continuing, you accept that you have read our '}

					<Anchor href={'https://www.cuvva.com/support/cuvvas-terms-conditions'} target={'_blank'}>
						<Typography $type={'Body.Small'} $color={'textHighlight'} $display={'inline'}>
							{'terms'}
						</Typography>
					</Anchor>

					{' and '}

					<Anchor href={'https://www.cuvva.com/support/cuvva-privacy-policy'} target={'_blank'}>
						<Typography $type={'Body.Small'} $color={'textHighlight'} $display={'inline'}>
							{'privacy notice'}
						</Typography>
					</Anchor>

					{'.'}
				</Typography>
			</TermsAndConditions>
		</SearchVehicleContainer>
	);
};

const SearchVehicleContainer = styled(ContentContainer)`
	${ErrorMessage} {
		min-height: 12px;
	}
`;

const InputWrap = styled.div`
	input {
		width: 100%;
	}
`;

const TermsAndConditions = styled.div`
	margin-top: ${p => p.theme.spacing.extraLarge};
	text-align: center;

	a {
		text-decoration: none;
	}
`;

const Pill = styled.button`
	border: none;
	margin: 0;
	appearance: none;
	background: ${p => p.theme.ui.primaryActionMuted};
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	padding: ${p => p.theme.spacing.small};
	color: ${p => p.theme.ui.primaryAction};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	gap: ${p => p.theme.spacing.small};

	${Typography} {
		white-space: nowrap;
	}
`;

const PillsContainer = styled.div`
	display: flex;
	overflow-x: auto;
	flex-direction: row;
	gap: ${p => p.theme.spacing.small};
`;

const RecentSearchesContainer = styled.div`
	margin-top: ${p => p.theme.spacing.large};
`;

export default SearchVehicle;
