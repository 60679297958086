import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { Anchor } from '../../atoms';
import Typography from '../../design-system/components/Typography';
import Button from '~lib/frontend/design-system/components/button/Button';
import Card, { CardBody } from '~lib/frontend/design-system/components/card/Card';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
// eslint-disable-next-line no-process-env
const isProduction = process.env.NODE_ENV === 'production';
const SimpleErrorBoundary = props => {
    const { reset, message, handleReset, children } = props;
    return (React.createElement(ErrorBoundary, { resetKeys: [reset], onReset: reset ? handleReset : () => null, fallbackRender: ({ error, resetErrorBoundary }) => (React.createElement(ErrorFallback, { error: error, message: message, resetErrorBoundary: resetErrorBoundary })), onError: (error, info) => {
            if (!isProduction)
                return;
            Sentry?.withScope(scope => {
                scope.setExtras(info);
                Sentry.captureException(error);
            });
        } }, children));
};
const ErrorFallback = props => {
    const history = useHistory();
    const { push } = history;
    const { error, message } = props;
    return (React.createElement(Card, { error: true },
        React.createElement(CardBody, null,
            React.createElement(Typography, { "$type": 'Heading.Medium', "$color": 'textOnSurfaceBackground' }, 'Whoops! Something went wrong 🙈'),
            React.createElement(Typography, { "$type": 'Heading.Small' },
                React.createElement("pre", null, message || error.message)),
            React.createElement(Typography, { "$type": 'Body.Medium', "$color": 'textOnSurfaceBackground' }, 'Let\'s try and prevent this happening again. In the main time, you can reach out to us if you need any help'),
            React.createElement(ButtonWrapper, null,
                React.createElement(WrappedButton, null,
                    React.createElement(Button, { "$type": 'neutralMuted', onClick: () => push(createAwareUrl('/')) }, 'Go home')),
                React.createElement(Button, { as: Anchor, 
                    // @ts-ignore
                    href: 'mailto:support@cuvva.com', className: 'intercom-launcher' }, 'Get help')))));
};
export default SimpleErrorBoundary;
export const ButtonWrapper = styled.div `
	width: 100%;
	display: flex;
	padding-top: ${p => p.theme.spacing.regular};
`;
export const WrappedButton = styled.div `
	margin-right: ${p => p.theme.spacing.regular};
`;
