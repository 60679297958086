import React, { Fragment, useLayoutEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import Container from '../atoms/container';
import Overlay from '../atoms/overlay';
const modifiers = [
    {
        name: 'eventListeners',
        options: {
            scroll: true,
            resize: true,
        },
    },
    {
        name: 'preventOverflow',
        options: {
            rootBoundary: 'viewport',
            padding: 8,
        },
    },
    {
        name: 'offset',
        options: {
            offset: [0, 8],
        },
    },
];
const Menu = ({ reference, shown, onClose, children, placement = 'bottom-start', }) => {
    const [popperRef, setPopperRef] = useState(null);
    const { styles, attributes, forceUpdate } = usePopper(reference.current, popperRef, {
        placement,
        modifiers,
        strategy: 'fixed',
    });
    useLayoutEffect(() => {
        if (shown && forceUpdate)
            forceUpdate();
    }, [shown, forceUpdate]);
    const onClickOutside = () => {
        if (shown && onClose)
            onClose();
    };
    if (!reference.current)
        return null;
    return (React.createElement(Fragment, null,
        shown && onClose && (React.createElement(Overlay, { onClick: onClickOutside })),
        React.createElement(Container, { ref: n => setPopperRef(n), style: { ...styles.popper }, ...attributes.popper, "$shown": shown }, shown && children)));
};
export default Menu;
